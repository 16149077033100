<template>
  <div class="pbf">
    <BrandTabHarvests 
      :inurl="`/${props.type}/${props.id}/harvests`"
      :product-id="props.id"
      :category="props.type"
      />
  </div>
</template>

<script setup lang="ts">

interface Props {
  id: number
  type: string
}

const props = defineProps<Props>()

</script>
